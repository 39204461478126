import React from "react"
import { ArticleEntity } from "../../entities/ArticleEntity"
import { Category } from "../Category/Category"
import { StaticPageContainer } from "../StaticPageContainer/StaticPageContainer"
import { StaticPageHeading } from "../StaticPageHeading/StaticPageHeading"
import { Layout } from "../Layout/Layout"
import {
  getBookmarksFollowedUrl,
  getBookmarksLikesUrl,
  getBookmarksUrl,
} from "../../utils/i18nUrls"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { useIntl } from "react-intl"
import { connector, ContainerProps } from "./containers/BookmarksPage.container"
import { BookmarkEntity } from "../../entities/BookmarkEntity"
import { LikeEntity } from "../../entities/LikeEntity"
import { UserEntity } from "../../entities/UserEntity"
import { FollowEntity } from "../../entities/FollowEntity"

const Empty = props => (
  <div className="mt-8 text-slate-700 italic w-full p-10 rounded bg-slate-50 border-slate-100 border">
    {props.children}
  </div>
)

export const Wrapper: React.FC<{
  page: { title: string; description: string; tab: string }
  bookmarks: BookmarkEntity[]
  likes: LikeEntity[]
  follows: FollowEntity[]
  articles: ArticleEntity[]
  lang: string
  theme: any
  header: any
  aside: any
  footer: any
  user: UserEntity | null
  drawer: any
  language: string
  languages: string[]
  cookies: any
  authors: ArticleEntity["author"][]
  categories: ArticleEntity["category"][]
}> = props => {
  const intl = useIntl()

  const articlesBookmarked = props.articles.filter(({ id }) => {
    return props.bookmarks.find(bookmark => bookmark.id === id)
  })
  const articlesLiked = props.articles.filter(({ id }) => {
    return props.likes.find(like => like.id === id)
  })
  const articlesFromFollowing = props.articles.filter(({ author }) => {
    return props.follows.find(follow => follow.id === author.id)
  })

  return (
    <>
      <Layout
        theme={props.theme}
        header={props.header}
        aside={{
          following: true,
          language: props.language,
          languages: props.languages,
          author: {
            url: "",
            lang: props.language,
            staff: false,
            username: props.user?.username || "",
            bio: props.user?.username
              ? intl.formatMessage({
                  id: "bookmarks/aside/author/bio",
                })
              : intl.formatMessage({
                  id: "bookmarks/aside/author/not-connected/description",
                }),
            id: "",
            image: {
              alt: "",
              src: props.user?.avatar || "/logout.svg",
            },
            followable: false,
            name:
              props.user?.username ||
              intl.formatMessage({
                id: "bookmarks/aside/author/not-connected/title",
              }),
          },
          authors: props.authors.filter(({ id }) =>
            props.follows.find(follow => follow.id === id)
          ),
        }}
        drawer={props.drawer}
        language={props.language}
        languages={props.languages}
      >
        <StaticPageContainer>
          <StaticPageHeading
            categories={[
              {
                id: "bookmarks",
                hidden: false,
                lang: props.lang,
                name: intl.formatMessage({
                  id: "bookmarks/tabs/bookmarks",
                }),
                url: getBookmarksUrl(props.lang),
              },
              {
                id: "likes",
                hidden: false,
                lang: props.lang,
                name: intl.formatMessage({
                  id: "bookmarks/tabs/likes",
                }),
                url: getBookmarksLikesUrl(props.lang),
              },
              {
                id: "follow",
                hidden: false,
                lang: props.lang,
                name: intl.formatMessage({
                  id: "bookmarks/tabs/follows",
                }),
                url: getBookmarksFollowedUrl(props.lang),
              },
            ]}
            title={
              <span className="text-slate-900">
                <FormattedMessage id="bookmarks/title" />
              </span>
            }
            lang={props.lang}
            description={<FormattedMessage id="bookmarks/description" />}
            tab={props.aside.tab}
          />

          {props.aside.tab === "bookmarks" && (
            <>
              {articlesBookmarked.length > 0 && (
                <Category articles={articlesBookmarked} />
              )}
              {articlesBookmarked.length === 0 && (
                <Empty>
                  <FormattedMessage id="bookmarks/empty" />
                </Empty>
              )}
            </>
          )}

          {props.aside.tab === "likes" && (
            <>
              {articlesBookmarked.length > 0 && (
                <Category articles={articlesLiked} />
              )}
              {articlesBookmarked.length === 0 && (
                <Empty>
                  <FormattedMessage id="bookmarks/empty/likes" />
                </Empty>
              )}
            </>
          )}

          {props.aside.tab === "follow" && (
            <>
              {articlesFromFollowing.length > 0 && (
                <Category articles={articlesFromFollowing} />
              )}
              {articlesFromFollowing.length === 0 && (
                <Empty>
                  <FormattedMessage id="bookmarks/empty/followers" />
                </Empty>
              )}
            </>
          )}
        </StaticPageContainer>
      </Layout>
    </>
  )
}

export const Container: React.FC<ContainerProps> = props => (
  <Wrapper {...props} />
)

export const BookmarksPage = connector(Container)
