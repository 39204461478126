import { navigate } from "gatsby"
import { connect, ConnectedProps } from "react-redux"
import { ArticleEntity } from "../../../entities/ArticleEntity"
import { BookmarkEntity } from "../../../entities/BookmarkEntity"
import { LikeEntity } from "../../../entities/LikeEntity"
import { UserEntity } from "../../../entities/UserEntity"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (
  state: RootState,
  props: {
    page: { title: string; description: string; tab: string }
    articles: ArticleEntity[]
    lang: string
    theme: any
    header: any
    aside: any
    footer: any
    drawer: any
    language: string
    languages: string[]
    cookies: any
    authors: ArticleEntity["author"][]
    categories: ArticleEntity["category"][]
  }
) => ({
  ...props,
  bookmarks: Array.from(state.bookmarks.bookmarks.values()),
  likes: Array.from(state.likes.likes.values()),
  follows: Array.from(state.follows.follows.values()),
  user: state.auth.user,
})

export const connector = connect(mapState)
export type ContainerProps = ConnectedProps<typeof connector>
